import axios, { endpoints } from 'src/utils/axios';

import { setSession } from './utils';
import { api } from 'src/Api';

export const getSpecialities = async () => {
  try {
    const res = await axios.get(api.get_categories);

    return res?.data;
  } catch (error) {
    console.error('Error during sign in:', error);
    throw error;
  }
};

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useColorScheme, useTheme } from '@mui/material/styles';
import { iconButtonClasses } from '@mui/material/IconButton';
import { Iconify } from 'src/components/iconify';

import { useBoolean } from 'src/hooks/use-boolean';

import { allLangs } from 'src/locales';
import { _contacts, _notifications } from 'src/_mock';

import { Logo } from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks';

import { Main } from './main';
import { NavMobile } from './nav-mobile';
import { layoutClasses } from '../classes';
import { NavVertical } from './nav-vertical';
import { NavHorizontal } from './nav-horizontal';
import { _account } from '../config-nav-account';
import { Searchbar } from '../components/searchbar';
import { _workspaces } from '../config-nav-workspace';
import { MenuButton } from '../components/menu-button';
import { LayoutSection } from '../core/layout-section';
import { HeaderSection } from '../core/header-section';
import { StyledDivider, useNavColorVars } from './styles';
import { AccountDrawer } from '../components/account-drawer';
import { SettingsButton } from '../components/settings-button';
import { LanguagePopover } from '../components/language-popover';
import { ContactsPopover } from '../components/contacts-popover';
import { WorkspacesPopover } from '../components/workspaces-popover';
import { navData as dashboardNavData } from '../config-nav-dashboard';
import { NotificationsDrawer } from '../components/notifications-drawer';
import { Footer } from '../main/footer';
import { SignInButton } from '../components/sign-in-button';
import { NavButton } from '../components/nav-button';
import { Button } from '@mui/material';
import { signOut } from 'src/auth/context/jwt';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { SideMenuDrawer } from '../components/side-menu-drawer';

// ----------------------------------------------------------------------

export function DashboardLayout({ sx, children, header, data }) {
  const theme = useTheme();

  const mobileNavOpen = useBoolean();

  const settings = useSettingsContext();

  const navColorVars = useNavColorVars(theme, settings);

  const layoutQuery = 'lg';

  const navData = data?.nav ?? dashboardNavData;

  const isNavMini = settings.navLayout === 'mini';
  const isNavHorizontal = settings.navLayout === 'horizontal';
  const isNavVertical = isNavMini || settings.navLayout === 'vertical';
  const { mode, setMode } = useColorScheme();
  const { user, checkUserSession } = useAuthContext();

  return (
    <LayoutSection
      /** **************************************
       * Header
       *************************************** */
      headerSection={
        <HeaderSection
          layoutQuery={layoutQuery}
          disableElevation={isNavVertical}
          slotProps={{
            toolbar: {
              sx: {
                ...(isNavHorizontal && {
                  bgcolor: 'var(--layout-nav-bg)',
                  [`& .${iconButtonClasses.root}`]: {
                    color: 'var(--layout-nav-text-secondary-color)',
                  },
                  [theme.breakpoints.up(layoutQuery)]: {
                    height: 'var(--layout-nav-horizontal-height)',
                  },
                }),
              },
            },
            container: {
              maxWidth: false,
              sx: {
                ...(isNavVertical && { px: { [layoutQuery]: 5 } }),
              },
            },
          }}
          sx={header?.sx}
          slots={{
            topArea: (
              <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                This is an info Alert.
              </Alert>
            ),
            bottomArea: isNavHorizontal ? (
              <NavHorizontal
                data={navData}
                layoutQuery={layoutQuery}
                cssVars={navColorVars.section}
              />
            ) : null,

            leftArea: (
              <>
               <SideMenuDrawer data={_account} />
                {/* -- Nav mobile -- */}
                {/* <MenuButton
                  // onClick={mobileNavOpen.onTrue}
                  sx={{
                    mr: 1,
                    ml: -1,
                    // [theme.breakpoints.up(layoutQuery)]: { display: 'none' },
                  }}
                /> */}
                <NavMobile
                  data={navData}
                  open={mobileNavOpen.value}
                  onClose={mobileNavOpen.onFalse}
                  cssVars={navColorVars.section}
                />
                {/* -- Logo -- */}
                {isNavHorizontal && (
                  <Logo
                    sx={{
                      display: 'none',
                      [theme.breakpoints.up(layoutQuery)]: {
                        display: 'inline-flex',
                      },
                    }}
                  />
                )}
                {/* -- Divider -- */}
                {isNavHorizontal && (
                  <StyledDivider
                    sx={{
                      [theme.breakpoints.up(layoutQuery)]: { display: 'flex' },
                    }}
                  />
                )}
                {/* -- Workspace popover -- */}
                {/* <WorkspacesPopover
                  data={_workspaces}
                  sx={{ color: 'var(--layout-nav-text-primary-color)' }}
                /> */}
                <Box
                  sx={{
                    display: {
                      md: 'flex',
                      lg: 'flex',
                      sm: 'flex',
                      xs: 'none',
                    },
                  }}
                >
                  <Logo isSingle={false} />
                </Box>
              </>
            ),
            rightArea: (
              <Box display="flex" alignItems="center" gap={{ xs: 0.75, sm: 0.75 }}>
                {/* DARK MODE */}
                {/* <Box
                  onClick={() => {
                    settings.onUpdateField('colorScheme', mode === 'light' ? 'dark' : 'light');
                    setMode(mode === 'light' ? 'dark' : 'light');
                  }}
                  sx={{
                    width: 36,
                    height: 36,
                    bgcolor: 'transparent', // Darker icon background for contrast
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `1px solid ${theme.palette.text.disabled}`,
                  }}
                >
                  <Iconify
                    icon={
                      mode === 'light' ? 'solar:sun-bold-duotone' : 'material-symbols:dark-mode'
                    }
                    width={20}
                    height={20}
                    sx={{ color: theme.palette.text.primary }}
                  />
                </Box> */}

                {!user ? (
                  <>
                    <Button
                      router={RouterLink}
                      href={`${paths.auth.jwt.signIn}`}
                      color="primary"
                      variant="outlined"
                    >
                      Sign In
                    </Button>
                    <NavButton
                      label="Join"
                      variant="contained"
                      color="primary"
                      href={`${paths.auth.jwt.signUp}`}
                    />
                  </>
                ) : (
                  <AccountDrawer data={_account} />
                  // <Button
                  //   variant="outlined"
                  //   onClick={async () => {
                  //     await signOut();
                  //     await checkUserSession?.();
                  //   }}
                  // >
                  //   Logout
                  // </Button>
                )}

                {/* -- Searchbar -- */}
                {/* <Searchbar data={navData} /> */}
                {/* -- Language popover -- */}
                {/* <LanguagePopover data={allLangs} /> */}
                {/* -- Notifications popover -- */}
                {/* <NotificationsDrawer data={_notifications} /> */}
                {/* -- Contacts popover -- */}
                {/* <ContactsPopover data={_contacts} /> */}
                {/* -- Settings button -- */}
                {/* <SettingsButton /> */}
                {/* -- Account drawer -- */}
           
              </Box>
            ),
          }}
        />
      }
      /** **************************************
       * Sidebar
       *************************************** */
      sidebarSection={
        isNavHorizontal ? null : null
        // <NavVertical
        //   data={navData}
        //   isNavMini={isNavMini}
        //   layoutQuery={layoutQuery}
        //   cssVars={navColorVars.section}
        //   onToggleNav={() =>
        //     settings.onUpdateField(
        //       'navLayout',
        //       settings.navLayout === 'vertical' ? 'mini' : 'vertical'
        //     )
        //   }
        // />
      }
      /** **************************************
       * Footer
       *************************************** */
      footerSection={<Footer layoutQuery={layoutQuery} />}
      /** **************************************
       * Style
       *************************************** */
      cssVars={{
        ...navColorVars.layout,
        '--layout-transition-easing': 'linear',
        '--layout-transition-duration': '120ms',
        '--layout-nav-mini-width': '88px',
        '--layout-nav-vertical-width': '300px',
        '--layout-nav-horizontal-height': '64px',
        '--layout-dashboard-content-pt': theme.spacing(1),
        '--layout-dashboard-content-pb': theme.spacing(8),
        '--layout-dashboard-content-px': theme.spacing(5),
      }}
      sx={{
        [`& .${layoutClasses.hasSidebar}`]: {
          [theme.breakpoints.up(layoutQuery)]: {
            transition: theme.transitions.create(['padding-left'], {
              easing: 'var(--layout-transition-easing)',
              duration: 'var(--layout-transition-duration)',
            }),
            pl: isNavMini ? 'var(--layout-nav-mini-width)' : 'var(--layout-nav-vertical-width)',
          },
        },
        ...sx,
      }}
    >
      <Main isNavHorizontal={isNavHorizontal}>{children}</Main>
    </LayoutSection>
  );
}

import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { _socials } from 'src/_mock';
import { TwitterIcon, FacebookIcon, LinkedinIcon, InstagramIcon } from 'src/assets/icons';

import { Logo } from 'src/components/logo';
import { getSpecialities } from 'src/auth/context/jwt/footer';
import { support_email } from 'src/utils/support-emails';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function Footer({ layoutQuery, sx }) {
  const [SPECIALITIES, setSPECIALITIES] = useState([]);

  const get_categories = async () => {
    try {
      const res = await getSpecialities();
      setSPECIALITIES(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_categories();
  }, []);

  // Updated links array with reference to SPECIALITIES
  const LINKS = [
    {
      headline: 'Explore',
      children: [
        { name: 'Advisors', href: 'category/all' },
        { name: 'Specialities', href: paths.specialities }, // You can still include a link to the Specialities section here if needed
        // { name: 'My Horoscope', href: '#' },
        { name: 'Blogs', href: paths.blogs },
        { name: 'About Us', href: paths.about },
      ],
    },
    {
      headline: 'Specialities',
      children: SPECIALITIES, // Renders the specialities from the separate array
    },
    { headline: 'Contact', children: [{ name: support_email(), href: `mailto:${support_email()}` }] },
  ];

  const theme = useTheme();

  const gradientAnimationStyles = `
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

  return (
    <>
      <style>{gradientAnimationStyles}</style>
      <Box
        component="footer"
        sx={{
          position: 'relative',
          background: `linear-gradient(90deg, ${theme?.palette?.primary?.main}, ${theme?.palette?.primary?.darker})`,
          backgroundSize: '200% 200%',
          animation: 'gradient-animation 5s ease infinite',
          ...sx,
        }}
      >
        <Container
          sx={{
            pb: 5,
            pt: 5,
            textAlign: 'center',
            [theme.breakpoints.up(layoutQuery)]: { textAlign: 'unset' },
          }}
        >
          <Logo isSingle={false} sx={{ ml: { xs: 0, sm: 0, md: 0, lg: -1 } }} light />

          <Grid
            container
            sx={{
              mt: 3,
              justifyContent: 'center',
              [theme.breakpoints.up(layoutQuery)]: { justifyContent: 'space-between' },
            }}
          >
            <Grid {...{ xs: 12, [layoutQuery]: 3 }}>
              <Typography
                variant="body1"
                sx={{
                  mx: 'auto',
                  maxWidth: 280,
                  color: theme.palette.common.white,
                  [theme.breakpoints.up(layoutQuery)]: { mx: 'unset' },
                }}
              >
                {`Time to Uncover Guidance with Clarity: Let’s Connect Leading Psychic Advisors Anywhere, Anytime!
                  Soulfulpsychics have customers who have chosen our expert psychic advisors from the exclusive list in terms of highest level of accuracy when it comes to counselling & assistance. People just like you have screened as well as chosen our advisors, who are always prepared to educate, uplift, & empower you. `}
              </Typography>

              <Stack
                direction="row"
                sx={{
                  mt: 3,
                  mb: 5,
                  ml: -1.4,
                  justifyContent: 'center',
                  [theme.breakpoints.up(layoutQuery)]: { mb: 0, justifyContent: 'flex-start' },
                }}
              >
                {_socials.map((social) => (
                  <IconButton key={social.label} color="inherit">
                    {social.value === 'twitter' && <TwitterIcon />}
                    {social.value === 'facebook' && <FacebookIcon />}
                    {social.value === 'instagram' && <InstagramIcon />}
                    {social.value === 'linkedin' && <LinkedinIcon />}
                  </IconButton>
                ))}
              </Stack>
            </Grid>

            <Grid {...{ xs: 12, [layoutQuery]: 8 }}>
              <Stack
                spacing={5}
                sx={{
                  flexDirection: 'column',
                  [theme.breakpoints.up(layoutQuery)]: { flexDirection: 'row' },
                }}
              >
                {LINKS.map((list, index) => (
                  <Stack
                    key={list.headline}
                    spacing={1}
                    sx={{
                      width: 1,
                      alignItems: 'center',
                      [theme.breakpoints.up(layoutQuery)]: { alignItems: 'flex-start' },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: '1rem', color: theme.palette.common.white }}
                      component="body1"
                      variant="overline"
                    >
                      {list.headline}
                    </Typography>

                    {list.children.map((link) => (
                      <Link
                        sx={{ color: theme.palette.common.white }}
                        key={link.name}
                        component={RouterLink}
                        href={index === 1 ? `/category/${link.slug}` : link.href}
                        color="inherit"
                        variant="body1"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </Grid>

          <Typography
            variant="body2"
            sx={{
              mt: 10,
              color: theme.palette.common.white,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span>© All rights reserved.</span>

            <Stack direction="row" spacing={2}>
              <Link
                sx={{ color: theme.palette.common.white }}
                component={RouterLink}
                href="/privacy-policy"
                color="inherit"
                variant="body2"
              >
                Privacy Policy
              </Link>
              <Link
                sx={{ color: theme.palette.common.white }}
                component={RouterLink}
                href="/terms-of-use"
                color="inherit"
                variant="body2"
              >
                Terms of Use
              </Link>
              <Link
                sx={{ color: theme.palette.common.white }}
                component={RouterLink}
                href="/cookie-policy"
                color="inherit"
                variant="body2"
              >
                Cookie Policy
              </Link>
              <Link
                sx={{ color: theme.palette.common.white }}
                component={RouterLink}
                href="/advisor-terms-and-conditions"
                color="inherit"
                variant="body2"
              >
                Advisor Terms and Conditions
              </Link>
            </Stack>
          </Typography>
        </Container>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

export function HomeFooter({ sx }) {
  return (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
        ...sx,
      }}
    >
      <Container>
        <Logo />
        <Box sx={{ mt: 1, typography: 'caption' }}>
          © All rights reserved.
          <br /> made by
          <Link href="https://minimals.cc/"> minimals.cc </Link>
        </Box>
      </Container>
    </Box>
  );
}

import axios, { endpoints } from 'src/utils/axios';

import { setSession } from './utils';
import { STORAGE_KEY } from './constant';
import { api } from 'src/Api';

/** **************************************
 * Sign in
 *************************************** */

export const signInWithPassword = async ({ email, password }) => {
  try {
    const params = { email, password };

    const res = await axios.post(api.signIn, params);

    const { access_token, data } = res.data;

    if (!access_token) {
      throw new Error('Access token not found in response');
    }
    localStorage.setItem('user', JSON.stringify(data));
    setSession(access_token);
    return res;
  } catch (error) {
    console.error('Error during sign in:', error);
    throw error;
  }
};

/** **************************************
 * Sign up
 *************************************** */

// export const signUp = async ({ email, password, firstName, lastName }) => {
//   const params = {
//     email,
//     password,
//     firstName,
//     lastName,
//   };

//   try {
//     const res = await axios.post(endpoints.auth.signUp, params);

//     const { access_token } = res.data;

//     if (!access_token) {
//       throw new Error('Access token not found in response');
//     }

//     sessionStorage.setItem(STORAGE_KEY, access_token);
//   } catch (error) {
//     console.error('Error during sign up:', error);
//     throw error;
//   }
// };

export const signUp = async ({ email, password, name, password_confirmation }) => {
  const params = { email, password, name, password_confirmation };

  try {
    const res = await axios.post(api.signUp, params);

    const { access_token, data } = res.data;

    if (!access_token) throw new Error('Access token not found in response');

    setSession(access_token);
    localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
};

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async () => {
  try {
    await setSession(null);
  } catch (error) {
    console.error('Error during sign out:', error);
    throw error;
  }
};

export const verifyOtp = async ({ email, otp, type }) => {
  const params = { email, otp, type };

  try {

    const res = await axios.post(type === 'forgot_password' ? api.verifyForgotOtp : api.verifyOtp, params);

    // const { access_token, data } = res.data;

    // if (!access_token) throw new Error('Access token not found in response');

    // setSession(access_token);
    // localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
};

export const resendOtp = async ({ email, type }) => {
  const params = { email, type };

  try {
    const res = await axios.post(api.resendOtp, params);
    console.log(res);

    // const { access_token, data } = res.data;

    // if (!access_token) throw new Error('Access token not found in response');

    // setSession(access_token);
    // localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
};

export const resetPassword = async ({ email }) => {
  const params = { email };

  try {
    const res = await axios.post(api.fotgotPassword, params);
    console.log(res);

    // const { access_token, data } = res.data;

    // if (!access_token) throw new Error('Access token not found in response');

    // setSession(access_token);
    // localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
}

export const updatePassword = async ({ email, password, password_confirmation }) => {
  const params = { email, password, password_confirmation };

  try {
    const res = await axios.post(api.change_forgot_password, params);
    console.log(res);

    // const { access_token, data } = res.data;

    // if (!access_token) throw new Error('Access token not found in response');

    // setSession(access_token);
    // localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
}

export const updateUserProfile = async ({ name, location, contact_no }) => {
  const params = { name, location, contact_no };

  try {
    const res = await axios.post(api.updateProfile, params);
    console.log(res);
    const { data } = res.data;
    localStorage.setItem('user', JSON.stringify(data));

    // const { access_token, data } = res.data;

    // if (!access_token) throw new Error('Access token not found in response');

    // setSession(access_token);
    // localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res.data;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
}

export const ChangeAvatar = async ({ file, user }) => {
  try {
    const formData = new FormData();
    formData.append('avatar', file);

    const res = await axios.post(api.updateAvatar, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Specify multipart form data
      },
    });

    const { data } = res.data;
    localStorage.setItem('user', JSON.stringify(data)); // Store updated user data
    return res.data; // Return response data for further handling
  } catch (error) {
    console.error('Error updating avatar:', error);
    throw error; // Rethrow error for handling in the UI
  }
};

export const changePassword = async ({ current_password, new_password, new_password_confirmation }) => {
  const params = { current_password, new_password, new_password_confirmation };

  try {
    const res = await axios.post(api.change_password, params);
    console.log(res);

    // const { access_token, data } = res.data;

    // if (!access_token) throw new Error('Access token not found in response');

    // setSession(access_token);
    // localStorage.setItem('user', JSON.stringify(data)); // store user data
    return res;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
}


/** **************************************
 * CHANGE EMAIL REQUEST
 *************************************** */

export const ChangeEmailRequest = async () => {
  try {
    const res = await axios.post(api.change_email_otp);

    return res.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error; // Rethrow the error to handle it in the UI
  }
};

// /** **************************************
//  * EMAIL OTP VERIFY
//  *************************************** */

// export const VerifyEmailOtp = async ({ otp, email }) => {
//   try {
//     const formData = new FormData();
//     // eslint-disable-next-line
//     email && formData.append('email', email);
//     otp && formData.append('otp', otp);

//     const res = await axios.post(api.change_email_otp, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });

//     return res.data;
//   } catch (error) {
//     console.error('Error updating profile:', error);
//     throw error; // Rethrow the error to handle it in the UI
//   }
// };

/** **************************************
 * EMAIL UPDATE
 *************************************** */

export const ChangeEmailOtp = async ({ email }) => {
  try {
    const formData = new FormData();
    // eslint-disable-next-line
    email && formData.append('email', email);

    const res = await axios.post(api.change_email, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error; // Rethrow the error to handle it in the UI
  }
};
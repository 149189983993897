export const mainurl = process.env.REACT_APP_MAINURL;
export const chatUrl = process.env.REACT_APP_CHATURL;

export const api = {
  category: `${mainurl}v1/get_categorized_consultants`,
  getConsultant: `${mainurl}v1/consultant/get_consultant_by_id`,
  get_categories: `${mainurl}v1/get_categories`,
  get_reviews: `${mainurl}v1/consultant/get_consultant_reviews`,
  signIn: `${mainurl}v1/users/login`,
  signUp: `${mainurl}v1/users/sign_up`,
  verifyOtp: `${mainurl}v1/users/verify_otp`,
  updateProfile: `${mainurl}v1/users/update_profile`,
  updateAvatar: `${mainurl}v1/users/update_profile_picture`,
  verifyForgotOtp: `${mainurl}v1/users/verify_forgot_password`,
  change_forgot_password: `${mainurl}v1/users/change_forgot_password`,
  change_password: `${mainurl}v1/users/change_password`,
  change_email_otp: `${mainurl}v1/users/change_email_otp`,
  change_email: `${mainurl}v1/users/change_email`,
  resendOtp: `${mainurl}v1/users/resend_otp`,
  addToFav: `${mainurl}v1/users/add_to_favourite`,
  addToNotify: `${mainurl}v1/users/add_to_notify`,
  checkFav: `${mainurl}v1/users/get_is_favourite`,
  fotgotPassword: `${mainurl}v1/users/forgot_password`,
  get_category_with_consultants: `${mainurl}v1/get_category_with_consultants`,
  get_consultant_for_about_page: `${mainurl}v1/get_consultant_for_about_page`,
};

import { forwardRef } from 'react';
import { Icon, disableCache } from '@iconify/react';

import Box from '@mui/material/Box';

import { iconifyClasses } from './classes';

// ----------------------------------------------------------------------

export const Iconify = forwardRef(({ className, width = 20, sx, ...other }, ref) => (
  <Box
    ssr
    ref={ref}
    component={Icon}
    className={iconifyClasses.root.concat(className ? ` ${className}` : '')}
    sx={{
      width: {
        xs: width * 0.7, // Smaller width for extra small devices
        sm: width * 0.7, // Slightly larger for small devices
        md: width, // Default width for medium and larger devices
      },
      height: {
        xs: width * 0.7,
        sm: width * 0.7,
        md: width,
      },
      flexShrink: 0,
      display: 'inline-flex',
      ...sx,
    }}
    {...other}
  />
));

// Disable Iconify cache
disableCache('local');

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Iconify } from 'src/components/iconify';

const InfoCard = ({ icon, heading, buttonLabel, onButtonClick, subDescription }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      border: '0px solid #e0e0e0',
      borderRadius: '8px',
      boxShadow: 0,
      mb: 6,
      flexDirection: { xs: 'column', md: 'row' }, // Change to column on small devices
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ flexGrow: 1 }}>
        {' '}
        {/* Allow this box to grow to take available space */}
        <Typography variant="h2">
          {icon && <Iconify icon={icon} sx={{ color: 'primary.main', mr: 0.25 }} width={35} />}{' '}
          {heading}
        </Typography>
        <Typography variant="h6" color="text.secondary" sx={{ mt: 2 }}>
          {subDescription}
        </Typography>
      </Box>
    </Box>
    {buttonLabel && (
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        sx={{ height: 40, mt: { xs: 2, md: 2, lg: 2, xl: 2 } }} // Adjust margin for small devices
      >
        {buttonLabel}
      </Button>
    )}
  </Box>
);

export default InfoCard;
